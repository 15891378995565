import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import i18next from "i18next";
import {
    Button
} from "react-bootstrap";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import CartContext from "../../context/internal/CartManager";
import ShopContext from "../../context/internal/ShopManager";

function ProductPageAddToCartButton({ product, variant = "success", size = "md", disabled }) {
    const productContext = useContext(ProductPageContext);
    const cartContext = useContext(CartContext);
    const shopContext = useContext(ShopContext);
    const [loading, setLoading] = useState(false);

    const productInternal = useMemo(() => {
        if(product) {
            return product;
        }
        return productContext?.product;
    }, [productContext, product]);

    const buttonText = useMemo(() => {
        if(!productInternal || !shopContext.shop) {
            return null;
        }
        if(!shopContext.shop.orderingEnabled) {
            return i18next.t("contactUs");
        }
        if(!productInternal.orderable) {
            return i18next.t(productInternal.digital ? "notAvailable" : "soldOut");
        }
        return i18next.t("addToCart");
    }, [productInternal, shopContext.shop]);
    const buttonIcon = useMemo(() => {
        if(!productInternal || !shopContext.shop) {
            return null;
        }
        if(loading) {
            return (
                <i className="fas fa-circle-notch fa-spin mr-2"/>
            )
        }
        if(!shopContext.shop.orderingEnabled) {
            return (
                <i className="fas fa-phone mr-2"/>
            );
        }
        if(!productInternal.orderable) {
            return (
                <i className="fas fa-shopping-cart mr-2"/>
            );
        }
        return (
            <i className="fas fa-cart-arrow-down mr-2"/>
        );
    }, [loading, shopContext, productInternal]);

    const onClick = useMemo(() => {
        return async () => {
            if(!shopContext.shop || !shopContext.shop.orderingEnabled || !productInternal.orderable) {
                return;
            }
            setLoading(true);
            await cartContext.addProductToCart(productInternal.id, 1);
            setLoading(false);
            productContext?.triggerAddedToCartModal();
        }
    }, [shopContext.shop, cartContext, productContext, productInternal]);

    if(!productInternal || !shopContext.shop) {
        return (
            <Skeleton height={ 42 } width={ 185 }/>
        )
    }
    let classNames = [];
    if(productContext) {
        classNames.push("product-page-add-to-cart-button");
    }
    if(!shopContext.shop.orderingEnabled) {
        classNames.push("btn");
        if(variant) {
            classNames.push("btn-" + variant);
        }
        if(size) {
            classNames.push("btn-" + size);
        }
        return (
            <Link
                to="/contact"
                className={ classNames.join(" ") }
                disabled={ disabled }
            >
                { buttonIcon }
                { buttonText }
            </Link>
        )
    }
    return (
        <Button
            variant={ variant }
            size={ size }
            className={ classNames.join(" ") }
            onClick={ onClick }
            disabled={ !productInternal.orderable || loading || disabled }
        >
            { buttonIcon }
            { buttonText }
        </Button>
    )
}

export default React.memo(ProductPageAddToCartButton);
