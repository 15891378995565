import React, {
    useMemo
} from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

function Markdown({ children, gfm, remarkPlugins, ...props }) {
    const internalPlugins = useMemo(() => {
        let pluginsList = [];
        if(gfm) {
            pluginsList.push(remarkGfm);
        }
        if(remarkPlugins) {
            pluginsList = pluginsList.concat(remarkPlugins);
        }
        return pluginsList;
    }, [gfm, remarkPlugins]);

    return (
        <ReactMarkdown
            remarkPlugins={ internalPlugins }
            { ...props }
        >
            { children }
        </ReactMarkdown>
    )
}

export default React.memo(Markdown);
