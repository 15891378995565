import React from "react";
import {
    Link
} from "react-router-dom";
import i18next from "i18next";

import CourseErrorContent from "./CourseErrorContent";

function CourseErrorState({ error = i18next.t("errorGeneral") }) {
    return (
        <div className="shopcrate-course-fullscreen">
            <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center flex-grow-1">
                <CourseErrorContent error={error} />
                <Link to="/account/courses" className="btn btn-primary">
                    Terug naar cursussen
                </Link>
            </div>
        </div>
    );
}

export default React.memo(CourseErrorState);
