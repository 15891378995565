import React, {
    useContext
} from "react";
import Skeleton from "react-loading-skeleton";

import {
    ProductPageContext
} from "../../context/ProductPageManager";
import CourseMarkdown from "../../page-content/account/tabs/courses/components/CourseMarkdown";

function ProductPageDescription() {
    const productContext = useContext(ProductPageContext);
    if(!productContext.product) {
        return (
            <Skeleton count={ 10 }/>
        );
    }
    return (
        <div className="markdown">
            <CourseMarkdown videoMaxWidth={ 640 }>
                { productContext.product.description }
            </CourseMarkdown>
        </div>
    );
}

export default React.memo(ProductPageDescription);
