import React from "react";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";
import {
    ErrorBoundary
} from "@sentry/react";

import CourseMarkdown from "./CourseMarkdown";
import CourseErrorContent from "./CourseErrorContent";

function CourseLessonContent({ chapter, mobile, lesson }) {
    return (
        <div className="d-flex flex-column">
            <div className="flex-grow-1">
                <div className="lesson-title">
                    <div className="d-flex flex-row align-items-center">
                        <div className="flex-grow-1">
                            <div className="pretitle">
                                { chapter.title }
                            </div>
                            <div className="title">
                                { lesson.title }
                            </div>
                        </div>
                        { !mobile && lesson.completed && (
                            <div className="lesson-completion-badge">
                                <i className="fas fa-check-circle mr-2"/>
                                <Trans i18nKey="completed"/>
                            </div>
                        )}
                    </div>
                </div>
                { mobile && lesson.completed && (
                    <div>
                        <div className="lesson-completion-badge mb-3">
                            <i className="fas fa-check-circle mr-2"/>
                            <Trans i18nKey="completed"/>
                        </div>
                    </div>
                )}
                <ErrorBoundary fallback={
                    <CourseErrorContent
                        error={ i18next.t("courseContentDisplayError") }
                    />
                }>
                    <div className="markdown">
                        <CourseMarkdown>
                            { lesson.content }
                        </CourseMarkdown>
                    </div>
                </ErrorBoundary>
            </div>
        </div>
    )
}

export default React.memo(CourseLessonContent);
