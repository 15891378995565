import React, {
    useContext,
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import ReactPlayer from "react-player";
import * as Sentry from "@sentry/react";

import VideoPlayer from "../../../../../components/VideoPlayer";
import ShopContext from "../../../../../context/internal/ShopManager";
import Markdown from "../../../../../components/Markdown";

function MarkdownLink({ node, href, children, videoMaxWidth = null, ...linkProps }) {
    const shopContext = useContext(ShopContext);
    const currentUrl = new URL(window.location.href);
    const url = useMemo(() => {
        try {
            return new URL(href);
        } catch(urlError) {
            console.error(urlError);
            Sentry.captureException(urlError);
            return null;
        }
    }, [href]);

    if(ReactPlayer.canPlay(href)) {
        return (
            <VideoPlayer
                url={ href }
                maxWidth={ videoMaxWidth ?? shopContext.courseVideoMaxWidth }
                resizeWindowHeight
            />
        );
    }
    const localUrl = currentUrl.protocol === url?.protocol && currentUrl.hostname === url?.hostname;
    if(url && localUrl) {
        return (
            <Link to={ url.pathname }>
                { children }
            </Link>
        );
    }
    return (
        <a
            href={ href }
            target="_blank"
            rel="noopener noreferrer"
            { ...linkProps }
        >
            { children }
        </a>
    );
}

function CourseMarkdown({ children }) {
    return (
        <Markdown
            gfm
            components={{
                a: MarkdownLink
            }}
        >
            { children }
        </Markdown>
    );
}

export default React.memo(CourseMarkdown);
