import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import Skeleton from "react-loading-skeleton";
import {
    Trans
} from "react-i18next";

import CartContext from "../../context/internal/CartManager";
import shopCrateAPI from "../../ShopCrateAPI";
import {
    ProductCardSmall
} from "../product/ProductCard";
import ProductPageAddToCartButton from "../product/ProductPageAddToCartButton";

function CartOrderBumps() {
    const { cart, cartToken } = useContext(CartContext);
    const [products, setProducts] = useState(null);
    const lastCartIdRef = useRef(null);

    const getOrderBumps = useCallback(async (cartTokenInternal) => {
        setProducts(null);
        try {
            const response = await shopCrateAPI.post("/getCartOrderBumps", {
                cartToken: cartTokenInternal,
            });
            setProducts(response.data.products);
        } catch(requestError) {
            console.error(requestError);
            setProducts([]);
        }
    }, []);

    useEffect(() => {
        if(!cart || !cartToken) {
            return;
        }
        if(cart.id === lastCartIdRef.current) {
            return;
        }
        lastCartIdRef.current = cart.id;
        getOrderBumps(cartToken);
    }, [cart, cartToken, getOrderBumps]);

    if (!products?.length) {
        return null;
    }

    return (
        <div className="card mb-2">
            <div className="card-body">
                { !products ? (
                    <div className="d-flex flex-row w-100">
                        { [...Array(4)].map((value, index) => (
                            <Skeleton key={ index } style={ { width: "25%", height: "100px" } } />
                        )) }
                    </div>
                ) : (
                    <React.Fragment>
                        <h5 className="card-title">
                            <Trans i18nKey="orderBumpsTitle" />
                        </h5>
                        <div className="row">
                            { products.map((product) => (
                                <div
                                    key={ product.id }
                                    className="col-md-6 col-lg-3 d-flex flex-column flex-grow-1"
                                >
                                    <div className="flex-grow-1">
                                        <ProductCardSmall product={ product } />
                                    </div>
                                    <ProductPageAddToCartButton
                                        product={ product }
                                        size="sm"
                                        disabled={ cart.products.map((product) => product.id).includes(product.id) }
                                    />
                                </div>
                            )) }
                        </div>
                    </React.Fragment>
                ) }
            </div>
        </div>
    );
}

export default React.memo(CartOrderBumps);
