import React, {
    useContext,
    useMemo
} from "react";
import i18next from "i18next";

import ShopContext from "../../../context/internal/ShopManager";
import Markdown from "../../../components/Markdown";

function TermsAndConditionsText() {
    const { shop } = useContext(ShopContext);

    const text = useMemo(() => {
        const originalText = i18next.t("orderTermsAndConditionsText");
        const termsAndConditionsText = i18next.t("termsAndConditions");
        const privacyPolicyText = i18next.t("privacyPolicy");

        let newText = originalText;
        if(shop?.termsAndConditionsUrl) {
            newText = newText.replace("{terms}", `[${termsAndConditionsText}](${shop.termsAndConditionsUrl})`);
        } else {
            newText = newText.replace("{terms}", termsAndConditionsText);
        }
        if(shop?.privacyPolicyUrl) {
            newText = newText.replace("{privacy}", `[${privacyPolicyText}](${shop.privacyPolicyUrl})`);
        } else {
            newText = newText.replace("{privacy}", privacyPolicyText);
        }
        return newText;
    }, [shop]);

    return (
        <Markdown
            disallowedElements={["p"]}
            unwrapDisallowed
            components={{
                a: ({ children, href, ...props }) => (
                    <a
                        {...props}
                        href={ href }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        { children }
                    </a>
                )
            }}
        >
            { text }
        </Markdown>
    );
}

export default React.memo(TermsAndConditionsText);
