import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {
    Link,
    useHistory
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    Trans
} from "react-i18next";
import i18next from "i18next";

import CartContext from "../../context/internal/CartManager";
import AuthenticationContext from "../../context/internal/AuthenticationManager";
import OrderContext from "../../context/internal/OrderManager";
import ShopContext from "../../context/internal/ShopManager";
import Loading from "../../components/Loading";
import CartCard from "../../components/cart/CartCard";
import CartPriceSummary from "../../components/cart/CartPriceSummary";
import CartSelectCountry from "../../components/cart/CartSelectCountry";
import CartFulfillmentMethodCard from "../../components/cart/CartFulfillmentMethodCard";
import CartDiscountCodes from "../../components/cart/CartDiscountCodes";
import CartOrderBumps from "../../components/cart/CartOrderBumps";

function CartPageContent() {
    const cartContext = useContext(CartContext);
    const authentication = useContext(AuthenticationContext);
    const orderContext = useContext(OrderContext);
    const shopContext = useContext(ShopContext);
    const history = useHistory();
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!shopContext.shop || !shopContext.shop.shippingCosts) {
            return;
        }
        if(orderContext.countries === null) {
            orderContext.getCountries();
        }
    }, [shopContext, orderContext]);

    const authenticationStatus = useMemo(() => {
        return authentication.getAuthenticationStatus();
    }, [authentication]);
    const orderUrl = useMemo(() => {
        switch(authenticationStatus) {
            case "unsupported":
            case "loggedin":
                return "/order/data";
            default:
                return "/order/account-selection";
        }
    }, [authenticationStatus]);

    const onError = useCallback((newError) => {
        setError(newError);
        window.scroll({ top: 0, behavior: "smooth" });
    }, []);
    const nextStep = useCallback(() => {
        if(shopContext.shop.shippingCosts && orderContext.deliveryInfo.deliveryCountry.length === 0) {
            onError(i18next.t("errorNoCountrySelected"));
            return;
        }
        history.push(orderUrl);
    }, [history, shopContext.shop, orderContext.deliveryInfo.deliveryCountry, orderUrl, onError]);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">
                    { error }
                </Alert>
            )}
            { cartContext.error !== null ? (
                <Alert variant="danger">
                    { cartContext.error }
                </Alert>
            ) : shopContext.error !== null ? (
                <Alert variant="danger">
                    { shopContext.error }
                </Alert>
            ) : orderContext.countriesError !== null ? (
                <Alert variant="danger">
                    { orderContext.countriesError }
                </Alert>
            ) : cartContext.cart === undefined || !shopContext.shop || authenticationStatus === "unknown" || orderContext.isLoading() ? (
                <Loading/>
            ) : !shopContext.shop.orderingEnabled ? (
                <Alert variant="danger">
                    <Trans i18nKey="errorOrderingDisabled"/>
                </Alert>
            ) : !cartContext.cart || cartContext.cart.products.length === 0 ? (
                <div className="text-center">
                    <i className="fas fa-shopping-cart mb-2" style={{ fontSize: "3rem" }}/>
                    <h1>
                        <Trans i18nKey="cartEmptyTitle"/>
                    </h1>
                    <Link to="/" className="btn btn-primary">
                        <Trans i18nKey="cartEmptyButton"/>
                    </Link>
                </div>
            ) : (
                <div className="row">
                    <div className="col-md-9">
                        { cartContext.cart.products.map((product) => (
                            <CartCard
                                key={ product.id }
                                product={ product }
                                amount={ product.count }
                                setProductCount={ cartContext.setCartProductCount }
                                removeProduct={ cartContext.removeProductFromCart }
                            />
                        )) }
                        <CartFulfillmentMethodCard/>
                        <CartSelectCountry/>
                        <CartDiscountCodes/>
                        <CartPriceSummary className="mb-2"/>
                        <CartOrderBumps/>
                    </div>
                    <div className="col-md-3 d-flex flex-column align-items-end align-items-md-start justify-content-end justify-content-md-start">
                        <Button variant="success" className="px-4 py-2" onClick={ nextStep }>
                            <Trans i18nKey="cartOrderButton"/>
                        </Button>
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default React.memo(CartPageContent);
