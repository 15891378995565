import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";

import ProductImage from "../ProductImage";
import ShopContext from "../../context/internal/ShopManager";
import ProductImagePlaceholder from "../ProductImagePlaceholder";
import PriceDisplay from "../PriceDisplay";
import ProductAvailabilityCheckmark from "./ProductAvailabilityCheckmark";

export function ProductCardSmall({ product, noLink, noPrice }) {
    const shopContext = useContext(ShopContext);
    const image = product.images?.length === 0 ? null : product.images?.[0];
    const url = "/" + (product.rental ? "rental" : "product") + "/" + product.id + "/" + product.url;
    const content = (
        <React.Fragment>
            <div className="d-flex justify-content-center align-content-center rounded" style={{ height: "120px", backgroundColor: "white" }}>
                <ProductImage
                    image={ image }
                    size="175x130"
                    className="img-fluid rounded"
                    alt="Product"
                    style={{ objectFit: "contain" }}
                />
            </div>
            <h6 className="mt-2">{ product.name }</h6>
        </React.Fragment>
    )
    return (
        <div>
            { noLink ? content : (
                <Link to={ url }>
                    { content }
                </Link>
            )}
            { shopContext.shop.showPricing && !noPrice && (
                <p className="mb-0">
                    <PriceDisplay product={ product }/>
                </p>
            )}
            { shopContext.shop.orderingEnabled && (
                <ProductAvailabilityCheckmark product={ product } short/>
            )}
        </div>
    );
}

export function ProductCardLarge({ product, noLink, noPrice }) {
    const shopContext = useContext(ShopContext);
    const url = "/" + (product.rental ? "rental" : "product") + "/" + product.id + "/" + product.url;
    const image = product.images?.length === 0 ? null : product.images?.[0];
    const content = (
        <React.Fragment>
            <div
                className="d-flex justify-content-center align-content-center rounded"
                style={{ height: "250px", backgroundColor: "white" }}
            >
                { image ? (
                    <img
                        src={ image.url }
                        className="img-fluid rounded"
                        alt="Product"
                        style={{ objectFit: "contain" }}
                    />
                ) : (
                    <ProductImagePlaceholder/>
                )}
            </div>
            <h6 className="mt-2">{ product.name }</h6>
        </React.Fragment>
    )
    return (
        <div className="text-center">
            { noLink ? content : (
                <Link to={ url }>
                    { content }
                </Link>
            )}
            { shopContext?.shop.showPricing && !noPrice && (
                <p className="mb-0">
                    <PriceDisplay product={ product }/>
                </p>
            )}
        </div>
    )
}

function ProductCard({ size = "small", product, noLink = false, noPrice = false }) {
    if(size === "small") {
        return (
            <ProductCardSmall
                product={ product }
                noLink={ noLink }
                noPrice={ noPrice }
            />
        );
    } else if(size === "large") {
        return (
            <ProductCardLarge
                product={ product }
                noLink={ noLink }
                noPrice={ noPrice }
            />
        );
    }
    return null;
}

export default React.memo(ProductCard);
