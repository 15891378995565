import React from "react";
import i18next from "i18next";
import {
    Trans
} from "react-i18next";

function CourseErrorContent({ error = i18next.t("errorGeneral") }) {
    return (
        <div className="text-center">
            <h1 className="text-danger">
                <i className="fas fa-exclamation-circle" />
            </h1>
            <h3>
                <Trans i18nKey="error" />
            </h3>
            <p className="mb-3">
                { error }
            </p>
        </div>
    );
}

export default React.memo(CourseErrorContent);
